<template>
    <div
        class="app-toolbar"
        :style="{
            '--toolbar-font-color': toolbarFontColor,
            backgroundColor: whitelabelColor,
        }"
    >
        <transition name="fade">
            <div v-if="userInfo" class="l-inline l-center-v">
                <router-link
                    v-if="hasMapAccess"
                    to="/map"
                    class="app-toolbar__link"
                >
                    <span>{{ $t('map') }}</span>
                </router-link>

                <router-link
                    v-if="hasDashboardAccess"
                    to="/dashboard"
                    class="app-toolbar__link"
                >
                    <span>{{ $t('link2') }}</span>
                </router-link>

                <router-link
                    v-if="hasRulesAccess"
                    to="/rules"
                    class="app-toolbar__link"
                >
                    <span>{{ $t('link3') }}</span>
                </router-link>

                <router-link
                    v-if="hasLsvaAccess"
                    to="/lsva"
                    class="app-toolbar__link"
                >
                    <span>{{ $t('lsva3') }}</span>
                </router-link>

                <div
                    class="app-toolbar__menu-icon"
                    @click="showMenu = !showMenu"
                >
                    <transition name="squash-swap">
                        <RemoveIcon v-if="showMenu" width="24" height="24" />

                        <MenuIcon v-else width="24" height="24" />
                    </transition>
                </div>
            </div>
        </transition>

        <transition name="slide-from-bottom">
            <div
                v-if="showMenu"
                class="app-toolbar__menu"
                @click="showMenu = false"
            >
                <router-link to="/map">
                    {{ $t('map') }}
                </router-link>

                <router-link v-if="hasDashboardAccess" to="/dashboard">
                    {{ $t('link2') }}
                </router-link>

                <router-link v-if="hasRulesAccess" to="/rules">
                    {{ $t('link3') }}
                </router-link>

                <router-link v-if="hasLsvaAccess" to="/lsva">
                    {{ $t('lsva3') }}
                </router-link>

                <router-link
                    v-if="hasNavigationAccess"
                    :to="{ name: 'navigation' }"
                >
                    {{ $t('navigation') }}
                </router-link>

                <router-link
                    v-if="isSuperuser"
                    :to="{ name: 'connectionHeatmap' }"
                >
                    {{ $t('connectionHeatmap') }}
                </router-link>

                <router-link :to="{ name: 'settings' }">
                    {{ $t('settings') }}
                </router-link>

                <router-link :to="{ name: 'changePassword' }">
                    {{ $t('changePassword') }}
                </router-link>

                <a @click="showSupportModal">
                    {{ $t('support') }}
                </a>

                <a @click="showUpdatesModal">
                    {{ $t('changelog') }}
                </a>

                <a @click="handleLogout">
                    {{ $t('logout') }}
                </a>

                <a v-if="canInstall" @click="handleInstall">
                    {{ $t('install') }}
                </a>
            </div>
        </transition>

        <div v-if="!accountSpecificLogo" class="l-inline l-center">
            <WhitelabledLogo class="block" height="20" light />
        </div>

        <transition name="fade">
            <div v-if="userInfo">
                <transition name="fade">
                    <div
                        v-if="isMapViewActive && !showMenu"
                        class="app-toolbar__map-controls"
                    >
                        <MapControls mobile />
                    </div>
                </transition>

                <a class="app-toolbar__link" @click="openDropdownPanel">
                    <span>
                        {{ userName }}

                        <ArrowDownIcon
                            :width="14"
                            :height="14"
                            class="app-toolbar__dropdown-icon"
                            :class="{
                                'app-toolbar__dropdown-icon--active': showDropdownPanel,
                            }"
                        />
                    </span>
                </a>

                <transition name="dropdown">
                    <div v-if="showDropdownPanel" class="app-toolbar__dropdown">
                        <router-link
                            v-if="hasNavigationAccess"
                            :to="{ name: 'navigation' }"
                        >
                            <NavigationIcon width="16" height="16" />

                            {{ $t('navigation') }}
                        </router-link>

                        <router-link
                            v-if="isSuperuser"
                            :to="{ name: 'connectionHeatmap' }"
                        >
                            <HeatmapIcon width="16" height="16" />

                            {{ $t('connectionHeatmap') }}
                        </router-link>

                        <router-link :to="{ name: 'settings' }">
                            <SettingsSliderIcon width="16" height="16" />

                            {{ $t('settings') }}
                        </router-link>

                        <router-link :to="{ name: 'changePassword' }">
                            <ChangePasswordIcon width="16" height="16" />

                            {{ $t('changePassword') }}
                        </router-link>

                        <a @click="showSupportModal">
                            <HeadphonesIcon width="16" height="16" />

                            {{ $t('support') }}
                        </a>

                        <a @click="showUpdatesModal">
                            <InfoIcon width="16" height="16" />

                            {{ $t('changelog') }}
                        </a>

                        <a @click="handleLogout">
                            <LogoutIcon width="16" height="16" />

                            {{ $t('logout') }}
                        </a>

                        <a v-if="canInstall" @click="handleInstall">
                            <InstallationIcon width="16" height="16" />

                            {{ $t('install') }}
                        </a>
                    </div>
                </transition>

                <VDialog />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { pwaHelper } from '@/utils'
import ArrowDownIcon from '@/components/icons/ArrowDownIcon'
import ChangePasswordIcon from '@/components/icons/ChangePasswordIcon'
import HeadphonesIcon from '@/components/icons/HeadphonesIcon'
import HeatmapIcon from '@/components/icons/HeatmapIcon'
import InfoIcon from '@/components/icons/InfoIcon'
import InstallationIcon from '@/components/icons/InstallationIcon'
import LogoutIcon from '@/components/icons/LogoutIcon'
import MapControls from '@/components/redesigned/MapControls'
import MenuIcon from '@/components/icons/MenuIcon'
import NavigationIcon from '@/components/icons/NavigationIcon'
import RemoveIcon from '@/components/icons/RemoveIcon'
import SettingsSliderIcon from '@/components/icons/SettingsSliderIcon'
import SupportModal from '@/components/redesigned/SupportModal'
import WhitelabledLogo from '@/components/WhitelabledLogo'
import Whitelabling from '@/mixins/Whitelabling'

export default {
    name: 'AppToolbar',
    components: {
        ArrowDownIcon,
        ChangePasswordIcon,
        HeadphonesIcon,
        HeatmapIcon,
        InfoIcon,
        InstallationIcon,
        LogoutIcon,
        MapControls,
        MenuIcon,
        NavigationIcon,
        RemoveIcon,
        SettingsSliderIcon,
        WhitelabledLogo,
    },
    mixins: [Whitelabling],
    data() {
        return {
            canInstall: false,
            showDropdownPanel: false,
            showMenu: false,
        }
    },
    computed: {
        ...mapState('auth', ['userInfo']),
        ...mapGetters('auth', [
            'hasDashboardAccess',
            'hasLsvaAccess',
            'hasMapAccess',
            'hasNavigationAccess',
            'hasRulesAccess',
            'isSuperuser',
        ]),
        isMapViewActive() {
            return this.$route.path.match(/^\/map/i)
        },
        userName() {
            const { first_name, last_name, username } = this.userInfo
            return `${first_name} ${last_name}`.trim() || username
        },
    },
    async mounted() {
        this.canInstall = await pwaHelper.canInstall()
    },
    methods: {
        ...mapActions('auth', ['logout']),
        handleLogout() {
            this.logout()
            this.$router.push('/login', () => {
                this.$router.go()
            })
        },
        handleInstall() {
            pwaHelper.install()
        },
        openDropdownPanel() {
            if (this.showDropdownPanel) {
                return
            }

            this.showDropdownPanel = true

            const listener = () => {
                document.body.removeEventListener('click', listener)
                this.showDropdownPanel = false
            }

            setTimeout(() => {
                document.body.addEventListener('click', listener)
            })
        },
        showSupportModal() {
            this.$modal.show(SupportModal)
        },
        showUpdatesModal() {
            pwaHelper.showChangelog()
        },
    },
}
</script>

<i18n>
{
    "en": {
        "changePassword": "Change password",
        "changelog": "Updates",
        "close": "Close",
        "connectionHeatmap": "Connection heatmap",
        "install": "Install",
        "map": "Map",
        "link2": "Management",
        "link3": "Rules",
        "lsva3": "LSVA 3",
        "logout": "Logout",
        "navigation": "Navigation",
        "settings": "Settings",
        "support": "Support"
    },
    "de": {
        "changePassword": "Passwort ändern",
        "changelog": "Updates",
        "close": "Schliessen",
        "connectionHeatmap": "Verbindungsqualität",
        "install": "Installieren",
        "map": "Karte",
        "link2": "Verwaltung",
        "link3": "Regeln",
        "lsva3": "LSVA 3",
        "logout": "Logout",
        "navigation": "Navigation",
        "settings": "Einstellungen",
        "support": "Support"
    },
    "fr": {
        "changePassword": "Modifier le mot de passe",
        "changelog": "Mises à jour",
        "close": "Fermer",
        "connectionHeatmap": "Qualité de la connexion",
        "install": "Installer",
        "map": "Carte",
        "link2": "Management",
        "link3": "Règles",
        "lsva3": "LSVA 3",
        "logout": "Se déconnecter",
        "navigation": "Navigation",
        "settings": "Paramètres",
        "support": "Assistance"
    },
    "it": {
        "changePassword": "Modificare Password",
        "changelog": "Aggiornamenti",
        "close": "Chiudere",
        "connectionHeatmap": "Qualità della connessione",
        "install": "Installare",
        "map": "Mappa",
        "link2": "Management",
        "link3": "Regole",
        "lsva3": "LSVA 3",
        "logout": "Logout",
        "navigation": "Navigazione",
        "settings": "Impostazione",
        "support": "Supporto"
    }
}
</i18n>

<style lang="scss" scoped>
.app-toolbar {
    flex: 0 0 64px;
    width: 100%;
    height: 64px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    background-color: $color-primary;
    user-select: none;

    & > * {
        position: relative;
        width: 50%;

        &:last-child {
            text-align: right;
        }
    }

    &__link {
        position: relative;
        display: inline-block;
        padding: 0 1rem;
        color: var(--toolbar-font-color, $color-primary-contrast);
        line-height: 64px;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;

        & > span {
            opacity: 0.7;
            transition: opacity 0.1s;
        }

        &:hover {
            & > span {
                opacity: 1;
            }
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -6px;
            border: 6px solid transparent;
            border-bottom-color: $color-accent;
            opacity: 0;
        }

        &.router-link-active {
            & > span,
            &::after {
                opacity: 1;
            }
        }
    }

    &__dropdown-icon {
        margin-left: 2px;
        transition: all 0.2s;

        &--active {
            margin-bottom: -1px;
            transform: rotateZ(180deg);
        }
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        margin: 6px 8px 12px;
        padding: 8px 0;
        background-color: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-size: 14px;
        text-align: left;
        z-index: 1000;

        a {
            display: block;
            padding: 12px 20px;
            transition: background-color 0.1s;
            cursor: pointer;

            &:hover {
                background-color: $color-gray-lighter-new;
            }

            svg {
                margin-right: 8px;
                margin-bottom: -3px;
            }
        }
    }

    &__menu-icon {
        display: none;
        color: var(--toolbar-font-color, $color-primary-contrast);
    }

    &__menu {
        position: fixed;
        top: 64px;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 100vw;
        height: 100vh;
        background-color: $color-gray-lighter-new;
        z-index: 10;

        a {
            margin-top: -64px;
            font-size: 32px;
            color: rgba(0, 0, 0, 0.3);
        }
    }

    &__map-controls {
        display: none;
    }

    @include respond-to('for-mobile-down') {
        flex-basis: 56px;
        height: 56px;

        &__link {
            display: none;
        }

        &__menu-icon {
            display: block;
        }

        &__menu {
            top: 56px;
        }

        &__map-controls {
            display: block;
        }
    }
}

.dropdown {
    &-enter-active,
    &-leave-active {
        transition: all 0.1s ease-out;
    }

    &-enter,
    &-leave-to {
        transform: translateY(-8px);
        opacity: 0;
    }
}

.fade {
    &-enter-active,
    &-leave-active {
        transition: all 0.2s;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}

.slide-from-bottom {
    &-enter-active,
    &-leave-active {
        transition: all 0.2s;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateY(1rem);
    }
}

.squash-swap {
    &-enter-active,
    &-leave-active {
        transition: all 0.2s;
    }

    &-enter,
    &-leave-to {
        position: absolute;
        transform: scaleY(0.1);
        opacity: 0;
    }
}
</style>
