import moment from 'moment'
import { httpHelper } from '@/utils'

const state = {
    calendarDays: [],
    isCalendarLoading: false,
    isCorrectionPending: false,
    isTrackPending: false,
    isTruckPending: false,
    isTrailersLoading: false,
    isTrucksLoading: false,
    isTripsLoading: false,
    isRevokePending: false,
    searchQuery: '',
    searchStatus: '',
    selectedDay: {},
    selectedTruck: null,
    trailers: [],
    trips: [],
    trucks: [],
}

const getters = {
    preparedCalendarDays(state) {
        return state.calendarDays
            .map(item => ({
                date: item.date,
                label: moment(item.date).format('dd'),
                dayNumber: moment(item.date).date(),
                monthAbbr: moment(item.date).format('MMM'),
                hasMarker: item.needs_manual_correction,
                url: item.url,
            }))
            .reverse()
    },
    preparedTrips(state) {
        return state.trips
            .reduce((acc, item) => {
                switch (item.type) {
                    case 'REGULAR':
                        return item.legs?.length
                            ? [
                                  ...acc,
                                  ...item.legs.map(leg => ({
                                      ...leg,
                                      type: item.type,
                                  })),
                              ]
                            : acc
                    case 'TRACK':
                        return [...acc, item]
                    case 'JOURNEY':
                        return item.reason === 'NO_JOURNEY'
                            ? [...acc, item]
                            : acc
                    default:
                        return acc
                }
            }, [])
            .map(item => {
                return {
                    ...item,
                    sortingDate:
                        item.type === 'TRACK' || item.type === 'JOURNEY'
                            ? item.correction_begin
                            : item.start,
                }
            })
            .sort((a, b) => {
                return new Date(a.sortingDate) - new Date(b.sortingDate)
            })
    },
    tripCorrections(state) {
        return state.trips.filter(
            item => item.type === 'TRAILER' || item.type === 'JOURNEY'
        )
    },
    allCorrections(state) {
        return state.trips.filter(
            item =>
                item.type === 'TRAILER' ||
                item.type === 'JOURNEY' ||
                item.type === 'TRACK'
        )
    },
    hasNoJourney(state) {
        return state.trips.some(
            item => item.type === 'JOURNEY' && item.reason === 'NO_JOURNEY'
        )
    },
}

const actions = {
    async loadCalendarDays({ commit }) {
        commit('setCalendarLoading', true)
        commit('setCalendarDays', [])

        const { data } = await httpHelper.get(
            'lsva/regular-toll-declaration-calendar/'
        )

        commit('setCalendarDays', Object.freeze(data))
        commit('setCalendarLoading', false)
    },
    async loadTrucks({ commit, state }) {
        commit('setTrucksLoading', true)
        commit('setTrucks', [])
        commit('setSelectedTruck', null)

        let query = ''

        if (state.searchQuery && state.searchQuery.length > 0) {
            query += `&search=${state.searchQuery}`
        }

        if (state.searchStatus && state.searchStatus !== 'ALL') {
            query += `&declaration_type=${state.searchStatus}`
        }

        const url = `${state.selectedDay.url}${query}`

        const { data } = await httpHelper.get(url)

        commit('setTrucks', Object.freeze(data.results))
        commit('setTrucksLoading', false)
    },
    async loadTrips({ commit, state }) {
        commit('setTripsLoading', true)
        commit('setTrips', [])

        const queryParams = new URLSearchParams()

        if (state.selectedTruck?.toll_declaration?.truck.id) {
            queryParams.append(
                'truck',
                state.selectedTruck.toll_declaration.truck.id
            )
        }

        if (state.selectedDay?.date) {
            queryParams.append('declaration_period', state.selectedDay.date)
        }

        const url = `lsva/declarations/?${queryParams.toString()}`

        const { data } = await httpHelper.get(url)

        commit('setTrips', Object.freeze(data?.results ?? []))
        commit('setTripsLoading', false)
    },
    async loadTrailers({ commit }) {
        commit('setTrailers', [])
        commit('setTrailersLoading', true)

        const { data } = await httpHelper.get('lsva/trailers/')

        commit('setTrailers', Object.freeze(data.results))
        commit('setTrailersLoading', false)
    },
    async createCorrection({ commit, dispatch, state }, data) {
        commit('setCorrectionPending', true)

        try {
            const response =
                data.type === 'TRAILER'
                    ? await httpHelper.post(
                          'lsva/manual-toll-declarations/trailer/',
                          {
                              declaration_period: state.selectedDay.date,
                              correction_begin: `${state.selectedDay.date}T${data.time[0]}`,
                              correction_end: `${state.selectedDay.date}T${data.time[1]}`,
                              trailer:
                                  data.trailer?.id !== 'NO_TRAILER' &&
                                  data.trailer?.id !== 'OTHER_TRAILER'
                                      ? data.trailer?.id
                                      : null,
                              trailer_weight: data.weight * 1000, // Convert to kilograms
                              trailer_type: data.trailerType,
                              truck:
                                  state.selectedTruck.toll_declaration.truck.id,
                          }
                      )
                    : await httpHelper.post(
                          'lsva/manual-toll-declarations/journey/',
                          {
                              declaration_period: state.selectedDay.date,
                              correction_begin: `${state.selectedDay.date}T${data.time[0]}`,
                              correction_end: `${state.selectedDay.date}T${data.time[1]}`,
                              truck:
                                  state.selectedTruck.toll_declaration.truck.id,
                              reason: data.reason,
                          }
                      )

            dispatch('loadTrips')
            return Promise.resolve(response)
        } catch (error) {
            return Promise.reject(error)
        } finally {
            commit('setCorrectionPending', false)
        }
    },
    async createTrack({ commit, dispatch, state }, data) {
        commit('setTrackPending', true)

        try {
            const response = await httpHelper.post(
                'lsva/manual-toll-declarations/track/',
                {
                    declaration_period: state.selectedDay.date,
                    correction_begin: `${state.selectedDay.date}T${data.time[0]}`,
                    correction_end: `${state.selectedDay.date}T${data.time[1]}`,
                    trailer:
                        data.trailer?.id !== 'NO_TRAILER' &&
                        data.trailer?.id !== 'OTHER_TRAILER'
                            ? data.trailer?.id
                            : null,
                    truck: state.selectedTruck.toll_declaration.truck.id,
                    trailer_weight: data.weight * 1000, // Convert to kilograms
                    trailer_type: data.trailerType,
                    distance: data.distance * 1000, // Convert to meters
                    reason: data.message,
                }
            )

            dispatch('loadTrips')
            return Promise.resolve(response)
        } catch (error) {
            return Promise.reject(error)
        } finally {
            commit('setTrackPending', false)
        }
    },
    async updateTruck({ commit, dispatch, state }, data) {
        commit('setTruckPending', true)

        const selectedTruckId = state.selectedTruck?.toll_declaration.truck.id

        try {
            const response = await httpHelper.post(
                'lsva/manual-toll-declarations/vin/',
                {
                    declaration_period: state.selectedDay.date,
                    old_vin: state.selectedTruck.toll_declaration.truck.vin,
                    replacement_vin: data.vin,
                    truck: state.selectedTruck.toll_declaration.truck.id,
                }
            )

            await dispatch('loadTrucks')
            commit(
                'setSelectedTruck',
                state.trucks.find(
                    truck => truck.toll_declaration.truck.id === selectedTruckId
                )
            )

            return Promise.resolve(response)
        } catch (error) {
            return Promise.reject(error)
        } finally {
            commit('setTruckPending', false)
        }
    },
    async revokeDeclaration({ commit, dispatch }, data) {
        commit('setRevokePending', true)

        try {
            const response = await httpHelper.post(
                'lsva/manual-toll-declarations/revocation/',
                {
                    declaration_period:
                        data.toll_declaration.declaration_period,
                    revoked_declaration:
                        data.toll_declaration.toll_declaration_id,
                }
            )

            return Promise.resolve(response)
        } catch (error) {
            return Promise.reject(error)
        } finally {
            commit('setRevokePending', false)
            dispatch('loadTrips')
        }
    },
    setSearchQuery({ commit, dispatch }, data) {
        commit('setSearchQuery', data)
        dispatch('loadTrucks')
    },
    setSearchStatus({ commit, dispatch }, data) {
        commit('setSearchStatus', data)
        dispatch('loadTrucks')
    },
    setSelectedDay({ commit, dispatch }, data) {
        commit('setSelectedDay', data)
        dispatch('loadTrucks')
    },
    setSelectedTruck({ commit, dispatch }, data) {
        commit('setSelectedTruck', data)
        dispatch('loadTrips')
    },
}
const mutations = {
    setCalendarLoading(state, data) {
        state.isCalendarLoading = data
    },
    setCalendarDays(state, data) {
        state.calendarDays = data
    },
    setTrucksLoading(state, data) {
        state.isTrucksLoading = data
    },
    setTrucks(state, data) {
        state.trucks = data
    },
    setSearchQuery(state, data) {
        state.searchQuery = data
    },
    setSearchStatus(state, data) {
        state.searchStatus = data
    },
    setSelectedDay(state, data) {
        state.selectedDay = data
    },
    setSelectedTruck(state, data) {
        state.selectedTruck = data
    },
    setTrips(state, data) {
        state.trips = data
    },
    setTripsLoading(state, data) {
        state.isTripsLoading = data
    },
    setTrailers(state, data) {
        state.trailers = data
    },
    setTrailersLoading(state, data) {
        state.isTrailersLoading = data
    },
    setCorrectionPending(state, data) {
        state.isCorrectionPending = data
    },
    setTrackPending(state, data) {
        state.isTrackPending = data
    },
    setTruckPending(state, data) {
        state.isTruckPending = data
    },
    setRevokePending(state, data) {
        state.isRevokePending = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
